<template>
  <div>
    <base-select
    :name="name"
    :label="label"
    :options="prefixes"
    :clearable="false"
    :reduce="code => code.dial_code"
    v-model="_value"
    @input="() => userChanged = !userChanged"
    selectClass="select-shadowed"
    optionLabel="dial_code">
    </base-select>
  </div>
</template>

<script>
import find from 'lodash/find'
import BaseSelect from 'theme/components/Form/BaseSelect'

export default {
  name: 'PhonePrifix',
  props: {
    selectedPrefix: {
      type: String
    },
    country: {
      type: String
    },
    value: {
      type: String
    },
    name: {
      type: String,
      required: false,
      default: ''
    },
    label: {
      type: String,
      required: false,
      default: ''
    }
  },
  data () {
    return {
      userChanged: false,
      prefixes: []
    }
  },
  computed: {
    _value: {
      get () {
        return this.value
      },
      set (newValue) {
        this.$emit('input', newValue)
      }
    }
  },
  mounted () {
    if (process.client) {
      this.getPrefix()
    }
  },
  methods: {
    async getPrefix () {
      let prefixes = await import('theme/helpers/phonePrefixCodes')
      this.prefixes = prefixes.default
      if (this.country && !this.userChanged && !this.selectedPrefix) {
        let prefix = find(this.prefixes, { code: this.country })
        if (prefix) {
          this.$emit('input', prefix.dial_code)
        }
      }
    }
  },
  watch: {
    country () {
      this.getPrefix()
    }
  },
  components: {
    BaseSelect
  }
}
</script>
