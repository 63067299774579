<template>
  <ul v-if="boutiques.length > 0" class="boutiques">
    <boutique
      @selectedBoutique="$emit('selectedBoutique', $event)"
      v-for="boutique in boutiques"
      :key="boutique.code"
      :boutique="boutique"
      :selected="boutique.code === boutiqueSelected.code"/>
  </ul>
  <!-- <p v-else class="no-boutiques mt-30">{{$t('bookAnAppointment.noBoutiques')}}</p> -->
</template>

<script>
import Boutique from './Boutique.vue'
export default {
  props: {
    boutiques: {
      type: Array,
      required: true,
      default: () => []
    },
    boutiqueSelected: {
      type: Object,
      required: true,
      default: () => {}
    }
  },
  components: {
    Boutique
  }
}
</script>
