<template>
  <div class="customer-care-pages book-an-appointment-page">
    <b-row class="layout-with-shoulder">
      <b-col cols="12" class="shoulder-left">
        <div class="stiky-position">
          <customer-care-link />
        </div>
      </b-col>
      <b-col cols="12" class="shoulder-content">
        <section class="book-an-appointment">
          <header class="header">
            <h1 class="title">{{$t('bookAnAppointment.title')}}</h1>
            <p class="subtitle" v-html="$t('bookAnAppointment.notice')"></p>
          </header>
          <section class="content">
            <b-form ref="bookAnAppointmentForm" data-vv-scope="bookAnAppointment" @submit.prevent="onSubmit" novalidate>
              <!-- FIRST STEP -->
              <fieldset class="step" id="selectBoutique">
                <legend>{{$t('bookAnAppointment.selectBoutique')}}</legend>
                <b-row no-gutters class="row-select-boutique">
                  <b-col md="6" class="pr-md-2 col-select-boutique">
                    <b-form-group id="groupCountry">
                      <base-select
                        name="country"
                        v-model="form.country"
                        v-validate="'required:true'"
                        @change="clearData(false)"
                        :class="{'is-invalid': errors.has('bookAnAppointment.country') }"
                        :label="$t('bookAnAppointment.selectCountry')+'*'"
                        :options="getCountries"
                        :clearable="false"
                        :searchable="false"
                        :validations="[{
                          condition: errors.has('bookAnAppointment.country'),
                          text: errors.first('bookAnAppointment.country')
                        }]">
                      </base-select>
                    </b-form-group>
                  </b-col>
                  <b-col md="6" class="pl-md-2 col-select-boutique">
                    <b-form-group id="groupCity">
                      <base-select
                        name="city"
                        v-model="form.city"
                        v-validate="'required:true'"
                        :class="{'is-invalid': errors.has('bookAnAppointment.city') }"
                        :disabled="!form.country"
                        :label="$t('bookAnAppointment.selectCity')+'*'"
                        :options="getCitiesFromCountry"
                        :clearable="false"
                        :searchable="false"
                        :validations="[{
                          condition: errors.has('bookAnAppointment.city'),
                          text: errors.first('bookAnAppointment.city')
                        }]">
                      </base-select>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row no-gutters class="row-select-boutique">
                  <b-col md="12">
                    <boutiques
                      @selectedBoutique="onSelectedBoutique($event)"
                      :boutiqueSelected="form.boutique"
                      :boutiques="getBoutiquesFromCity" />
                    <p
                      v-if="getBoutiquesFromCity.length <= 0 && form.city"
                      class="no-boutiques mt-3 text-center">
                        {{$t('bookAnAppointment.noBoutiques')}}
                    </p>
                  </b-col>
                </b-row>
              </fieldset>
              <!-- END -->
              <!-- SECOND STEP -->
              <fieldset :class="{'disabled': Object.keys(form.boutique) <= 0}" class="step" id="selectDate">
                <legend>{{$t('bookAnAppointment.selectDate')}}</legend>
                <b-row no-gutters class="row-select-date">
                  <b-col md="6" class="pr-md-2 col-select-date">
                    <b-form-group id="groupDate">
                      <dob
                        name="date"
                        v-model="form.date"
                        :autocomplete="'off'"
                        :minDate="minDate"
                        :maxDate="maxDate"
                        :required="true"
                        :showDatePicker="true"
                        :label="$t('bookAnAppointment.placeholderDate')+'*'"
                        :class="{'is-invalid': errors.has('date') }"
                        :oneinput="true">
                        <template #icon>
                          <i class="icomoon-icon icon-book-an-appointment"></i>
                        </template>
                      </dob>
                    </b-form-group>
                  </b-col>
                  <b-col md="6" class="pl-md-2 col-select-date">
                    <b-form-group id="groupTimeSlot">
                      <base-select
                        name="timeSlot"
                        v-model="form.timeSlot"
                        v-validate="'required:true'"
                        :label="$t('bookAnAppointment.selectTimeSlot')+'*'"
                        :class="{'is-invalid': errors.has('date') }"
                        :options="getTimeSlots"
                        :clearable="false"
                        :searchable="false">
                      </base-select>
                    </b-form-group>
                  </b-col>
                </b-row>
              </fieldset>
              <!-- END -->
              <!-- THIRD STEP -->
              <fieldset :class="{'disabled': !form.timeSlot || !form.date}" class="step" id="insertYourData">
                <legend>{{$t('bookAnAppointment.insertYourData')}}</legend>
                <b-row no-gutters class="row-insert-your-data">
                  <b-col md="6" class="pr-md-2 col-insert-your-data">
                    <b-form-group id="groupFirstname">
                      <base-input
                        type="text"
                        name="firstName"
                        v-model="form.firstName"
                        v-validate="'required'"
                        :class="[{'is-invalid': errors.has('bookAnAppointment.firstName')}, 'secondary']"
                        :label="$t('bookAnAppointment.firstName')+'*'"
                        :validations="[{
                            condition: errors.has('bookAnAppointment.firstName'),
                            text: errors.first('bookAnAppointment.firstName')
                        }]">
                      </base-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="6" class="pl-md-2 col-insert-your-data">
                    <b-form-group id="groupLastname">
                      <base-input
                        type="text"
                        name="lastName"
                        v-model="form.lastName"
                        v-validate="'required'"
                        :class="[{'is-invalid': errors.has('bookAnAppointment.lastName')}, 'secondary']"
                        :label="$t('bookAnAppointment.lastName')+'*'"
                        :validations="[{
                          condition: errors.has('bookAnAppointment.lastName'),
                          text: errors.first('bookAnAppointment.lastName')
                        }]">
                      </base-input>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row no-gutters class="row-insert-your-data">
                  <b-col md="6" class="pr-md-2 col-insert-your-data">
                    <b-form-group id="groupEmail">
                      <base-input
                        type="email"
                        name="email"
                        v-model="form.email"
                        v-validate="'required|email'"
                        :class="[{'is-invalid': errors.has('bookAnAppointment.email')}, 'secondary']"
                        :label="$t('bookAnAppointment.email')+'*'"
                        :validations="[{
                          condition: errors.has('bookAnAppointment.email'),
                          text: errors.first('bookAnAppointment.email')
                        }]">
                      </base-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="6" class="pl-md-2 col-insert-your-data">
                    <b-form-group id="groupPhone">
                      <phone-prefix
                      name="phone-prefix"
                      :country="countryLocale"
                      :selectedPrefix="phonePrefix"
                      :label="$t('bookAnAppointment.phone')+'*'"
                      v-model="phonePrefix"
                      />
                      <base-input
                        name="phone"
                        type="tel"
                        autocomplete="telephone"
                        v-validate="'required|phoneNumber'"
                        v-model="telephoneNumber"
                        :class="[{'is-invalid': errors.has('bookAnAppointment.phone')}, 'secondary']"
                        :validations="[{
                            condition: errors.has('bookAnAppointment.phone'),
                            text: errors.first('bookAnAppointment.phone')
                        }]">
                      </base-input>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row no-gutters class="row-insert-your-data">
                  <b-col md="6" class="pr-md-2 col-insert-your-data">
                    <b-form-group id="groupReason">
                      <base-select
                        name="reason"
                        v-model="form.reason"
                        :class="{'is-invalid': errors.has('date') }"
                        :label="$t('bookAnAppointment.howCanHelpYou')"
                        :options="getReasons"
                        :clearable="false"
                        :searchable="false">
                      </base-select>
                    </b-form-group>
                  </b-col>
                  <b-col md="6" class="pl-md-2 col-insert-your-data">
                    <b-form-group id="groupPreferences">
                      <base-select
                        name="preference"
                        v-model="form.preference"
                        :class="{'is-invalid': errors.has('bookAnAppointment.preference') }"
                        :label="$t('bookAnAppointment.preferencesNotice')"
                        :placeholder="$t('bookAnAppointment.preference')"
                        :options="getPreferences"
                        :clearable="false"
                        :searchable="false">
                      </base-select>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row no-gutters class="row-insert-your-data">
                  <b-col md="12" class="col-insert-your-data">
                    <b-form-group id="groupMessage">
                      <base-textarea
                        name="message"
                        v-model="form.message"
                        v-validate="'max:230'"
                        :label="$t('bookAnAppointment.message')"
                        :rows="12"
                        :validations="[{
                          condition: errors.has('bookAnAppointment.message'),
                          text: $t('bookAnAppointment.errorMessageTxt')
                        }]">
                      </base-textarea>
                      <div class="base-textarea-footer">
                        <p class="privacy-policy" :disabled="Object.keys(form.boutique) <= 0" v-html="getPrivacyPolicy"></p>
                        <p class="total-chars" :disabled="Object.keys(form.boutique) <= 0">{{ $t('bookAnAppointment.charactersAvailable') }} {{ totalcount }} </p>
                      </div>
                    </b-form-group>
                  </b-col>
                </b-row>
              </fieldset>
              <!-- END -->
              <div class="d-flex justify-content-end">
                <button type="submit" :disabled="Object.keys(form.boutique) <= 0" class="btn btn-primary">{{$t('bookAnAppointment.send')}}</button>
              </div>
            </b-form>
          </section>
        </section>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import uniqBy from 'lodash/uniqBy'
import { toast } from '@/modules/notifications'
import { pathTranslate } from '@/helpers/routeHelpers'
import PhonePrefix from 'theme/components/Form/PhonePrefix'
import CustomerCareLink from 'theme/components/CustomerCare/CustomerCareLink.vue'
import BaseSelect from 'theme/components/Form/BaseSelect'
import BaseInput from 'theme/components/Form/BaseInput'
import BaseTextarea from 'theme/components/Form/BaseTextarea'
import Boutiques from 'theme/components/BookAnAppointment/Boutiques.vue'
import Dob from 'theme/components/Form/Dob'
import Config from '../config'
import dayjs from 'dayjs'

export default {
  inject: ['$validator'],
  asyncData ({ store }) {
    store.commit('ui/showLoader')
    return store.dispatch('storeLocator/getAllBoutique')
      .finally(() => {
        store.commit('ui/hideLoader')
      })
  },
  data () {
    return {
      prefix: '',
      boutiques: this.$store.getters['storeLocator/getBoutiques'],
      minDate: new Date(),
      maxDate: new Date(new Date().setFullYear(new Date().getFullYear() + 1)), // add one year to today date
      form: {
        country: '',
        city: '',
        boutique: {},
        date: new Date(),
        timeSlot: '',
        reason: '',
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        preference: '',
        message: ''
      }
    }
  },
  computed: {
    countryLocale () {
      return this.$store.getters['storeConfig/language'].toUpperCase()
    },
    totalcount () {
      let maxCount = 230
      maxCount = maxCount - this.form.message.length
      return maxCount >= 0 ? maxCount : '0'
    },
    telephoneNumber: {
      get () {
        let phone = this.form.phone.split(' ')
        if (phone.length > 1) {
          return phone.slice(1).join('')
        } else {
          return phone[0]
        }
      },
      set (newValue) {
        this.form.phone = `${this.phonePrefix} ${newValue}`
        return this.form.phone
      }
    },
    phonePrefix: {
      get () {
        let prefix = this.form.phone.split(' ')
        if (prefix.length > 1 && prefix[0].length) {
          return prefix[0]
        } else {
          return this.prefix
        }
      },
      set (newValue) {
        this.prefix = newValue
        let phone = this.form.phone.split(' ')
        if (phone.length > 1) {
          phone[0] = newValue
          this.form.phone = phone.join(' ')
          return this.form.phone
        } else {
          return `${newValue} ${this.form.phone}`
        }
      }
    },
    getCurrentLang () {
      return this.$store.getters['ui/language']
    },
    getCountries () {
      if (this.boutiques && this.boutiques.length > 0) {
        return uniqBy(this.boutiques.map(boutique => boutique.country))
      }
      return []
    },
    getCitiesFromCountry () {
      if (this.form.country) {
        let boutiquesFromCountry = this.boutiques.filter(boutique => boutique.country === this.form.country)
        return uniqBy(boutiquesFromCountry.map(boutique => boutique.city))
      }
      return []
    },
    getBoutiquesFromCity () {
      if (this.form.city) {
        return this.boutiques.filter(boutique => boutique.city === this.form.city && boutique.book_appointment_enable === '1')
      }
      return []
    },
    getTimeSlots () {
      let currentDate = new Date(Date.now())
      let currentHour = currentDate.getHours()
      let dailySlot = Config.bookAnAppointment.timeSlots[this.getCurrentLang] || Config.bookAnAppointment.timeSlots.default || []
      let availableSlot = dailySlot.filter(time => time > (currentHour)).map(slot => `${slot}:00`)
      let selectedDate = new Date(`${this.form.date}`)
      if (selectedDate > currentDate) {
        availableSlot = dailySlot.map(slot => `${slot}:00`)
      }
      return availableSlot
    },
    getReasons () {
      return [
        this.$t('bookAnAppointment.reasons.discoverCollection'),
        this.$t('bookAnAppointment.reasons.findGift'),
        this.$t('bookAnAppointment.reasons.personalConsultancy'),
        this.$t('bookAnAppointment.reasons.findALook'),
        this.$t('bookAnAppointment.reasons.exchangesAndReturns'),
        this.$t('bookAnAppointment.reasons.other')
      ]
    },
    getPreferences () {
      return [
        this.$t('bookAnAppointment.preferences.noPreferences'),
        this.$t('bookAnAppointment.preferences.morning'),
        this.$t('bookAnAppointment.preferences.afteroon'),
        this.$t('bookAnAppointment.preferences.evening')
      ]
    },
    getPrivacyPolicy () {
      let linkPrivacy = this.$router.resolve({ name: 'factoryPage', params: { slug: pathTranslate({ path: 'privacy' }), lang: this.$store.state.ui.urlCode } }).href
      return this.$t('bookAnAppointment.privacyPolicy', { linkPrivacy })
    }
  },
  methods: {
    onSubmit (evt) {
      const _this = this
      if (evt) {
        evt.preventDefault()
      }
      this.$validator.validateAll('bookAnAppointment')
        .then(isValid => {
          if (isValid) {
            _this.$store.commit('ui/showLoader')
            let appointmentDate = this.form.date ? dayjs(this.form.date).format('YYYY-MM-DD') : ''
            let params = {
              appointment: {
                'customer_firstname': this.form.firstName,
                'customer_lastname': this.form.lastName,
                'customer_email': this.form.email,
                'customer_telephone': this.form.phone,
                'appointment_datetime': `${appointmentDate} ${this.form.timeSlot}`,
                'appointment_type': 'phisical', // AEFFE only physical appointment
                'boutique_code': this.form.boutique.code,
                'boutique_name': this.form.boutique.name,
                'appointment_reason': this.form.reason,
                'appointment_preference': this.form.preference,
                'appointment_message': this.form.message
              }
            }
            _this.$store.dispatch('storeLocator/bookAnAppointment', params)
              .then(res => {
                _this.openModalConfirmAppointment()
                _this.clearData()
              })
              .catch(() => {
                toast.error(_this.$t('GenericError'))
              })
              .finally(() => {
                _this.$store.commit('ui/hideLoader')
              })
          }
        })
    },
    openModalConfirmAppointment () {
      const componentToLoad = import('theme/components/Modal/ConfirmAppointment')
      this.$store.dispatch('ui/showDynamicModalComponent', {
        component: componentToLoad,
        propsBootstrapVueModal: {
          'hide-footer': true,
          'hide-header': false,
          'modal-class': 'modal-confirm-appointment',
          centered: true
        },
        props: {
          title: this.$t('bookAnAppointment.confirmAppointmentTitle'),
          text: this.$t('bookAnAppointment.confirmAppointmentMessage')
        }
      })
    },
    onSelectedBoutique (boutique) {
      this.form.boutique = boutique
    },
    clearData (flushAll = true) {
      this.$validator.reset()
      this.form = {
        country: flushAll ? '' : this.form.country,
        city: '',
        boutique: {},
        date: new Date(),
        timeSlot: '',
        reason: '',
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        preference: '',
        message: ''
      }
    }
  },
  components: {
    CustomerCareLink,
    BaseSelect,
    BaseInput,
    BaseTextarea,
    Dob,
    Boutiques,
    PhonePrefix
  }
}
</script>
