<template>
  <li :class="['boutique', {'selected': selected}]" @click.prevent="$emit('selectedBoutique', boutique)">
    <div class="header">
      <span class="name">{{boutique.name}}</span>
      <router-link :to="{ name: 'Boutique', params: { store_code: boutique.code } }" class="bcm-link bcm-link-underline-primary d-none d-md-inline">
        {{$t('bookAnAppointment.seeDetails')}}
      </router-link>
      <div class="fake-radio"></div>
      <label class="d-none d-md-inline">{{$t('bookAnAppointment.selectStoreRadio')}}</label>
    </div>
    <div class="footer">
      <span class="address">{{boutique.address}}</span>
      <router-link :to="{ name: 'Boutique', params: { store_code: boutique.code } }" class="bcm-link bcm-link-underline-primary d-inline d-md-none">
        {{$t('bookAnAppointment.seeDetails')}}
      </router-link>
    </div>
  </li>
</template>

<script>
export default {
  props: {
    boutique: {
      type: Object,
      required: true,
      default: () => {}
    },
    selected: {
      type: Boolean,
      required: true,
      default: false
    }
  }
}
</script>
