import { render, staticRenderFns } from "./PhonePrefix.vue?vue&type=template&id=0e3efd00&"
import script from "./PhonePrefix.vue?vue&type=script&lang=js&"
export * from "./PhonePrefix.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "PhonePrefix.vue"
export default component.exports